import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import BonAchat from "../containers/bon-achat"
import Contact from "../containers/contact"


const NoelPage = () => (
  <Layout>
    <SEO title="Noël 2023 🎄" />
    <BonAchat/>
    <Contact/>
  </Layout>

)
export default NoelPage
